<template>
  <v-checkbox v-model="value" :label="label" readonly dense hide-details class="w-checkbox mb-5"></v-checkbox>
</template>

<script>
import { VCheckbox } from "vuetify/lib";

export default {
  name: "w-checkbox",

  components: { VCheckbox },

  props: {
    value: { type: Boolean, default: false },
    label: { type: String, default: "" },
  },

  data: () => ({
    // menuOpen: false,
  }),

  //   computed: {
  //     getValue() {
  //       if (this.value) {
  //         return this.value;
  //       }
  //       return " ";
  //     },
  //   },

  methods: {
    // refreshTimeValue() {
    //   this.innerVal = this.def;
    // },
  },
};
</script>

<style scoped>
.w-checkbox {
  padding-top: 0px;
  margin-top: 0px;
}
.w-checkbox /deep/ .v-input__slot {
  height: 50px;
}
</style>
